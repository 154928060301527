import React from 'react'
import Homepage from '../views/Homepage'

import { getWpFrontpage } from '../data/home'

export default function Home (props) {
  return (
    <React.Fragment>
      <Homepage {...props}>
        <main id='content'></main>
      </Homepage>
    </React.Fragment>
  )
}

export async function getStaticProps ({ preview = false }) {
  const page = {
    ...(await getWpFrontpage())
  }

  return {
    props: {
      page
    }
  }
}
